<template>
  <aForm class="create-form" :form="form" @submit="handleSubmit">
    <a-row :gutter="[20, 0]" type="flex">
      <a-col :span="12">
        <a-form-item class="travel-input">
          <label for class="filled">Nome</label>
          <a-input
            v-decorator="[
              'first_name',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            placeholder="Nome"
          >
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item class="travel-input">
          <label for class="filled">Sobrenome</label>
          <a-input
            v-decorator="[
              'last_name',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            placeholder="Sobrenome"
          >
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item class="travel-input">
          <label for class="filled">E-mail</label>
          <a-input
            autoComplete="off"
            v-decorator="[
              'email',
              {
                rules: [
                  {
                    type: 'email',
                    message: 'Escreva um e-mail válido',
                  },
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            placeholder="E-mail"
          >
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item class="travel-input">
          <label for class="filled">Função do usuário</label>
          <a-select
            show-search
            placeholder="Função do usuário"
            @change="selectRole"
            v-decorator="[
              'role',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-select-option
              v-for="(item, index) of roles"
              :key="index"
              :value="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item class="travel-input">
          <label for class="filled">Celular</label>
          <a-input
            v-mask="'(##) # ####-####'"
            v-decorator="[
              'mobile_phone',
              {
                rules: [
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            placeholder="Celular"
          >
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item class="travel-input">
          <label for class="filled">Telefone Fixo/Ramal</label>
          <a-input
            v-mask="'(##) ####-####'"
            v-decorator="[
              'phone',
              {
                rules: [
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            placeholder="Telefone Fixo/Ramal"
          >
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item class="travel-input">
          <label for class="filled">Nascimento</label>
          <a-date-picker
            placeholder="Nascimento"
            format="DD/MM/YYYY"
            v-mask="'##/##/####'"
            :showToday="false"
            @openChange="defaultDate"
            v-decorator="[
              'birthday',
              {
                rules: [
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
          </a-date-picker>
        </a-form-item>
      </a-col>

      <div class="clearboth" style="color: transparent">.</div>

      <a-col :span="12">
        <a-form-item class="travel-input">
          <label for class="filled">Senha</label>
          <a-input-password
            class="travel-input"
            size="default"
            v-decorator="[
              'userPassword',
              {
                rules: [
                  {
                    required: true,
                    message: 'Escreva sua nova senha.',
                  },
                ],
              },
            ]"
            type="password"
            placeholder="Insira a senha"
          >
          </a-input-password></a-form-item
      ></a-col>
      <a-col :span="12">
        <a-form-item class="travel-input">
          <label for class="filled">Repita a senha</label>
          <a-input-password
            class="travel-input"
            size="default"
            v-decorator="[
              'password',
              {
                rules: [
                  {
                    required: true,
                    message: 'Repita a  senha.',
                  },
                  {
                    validator: compareToFirstPassword,
                  },
                ],
              },
            ]"
            type="password"
            placeholder="Repita a senha"
          >
          </a-input-password>
        </a-form-item>
      </a-col>
    </a-row>

    <a-form-item>
      Status:
      <a-switch
        size="small"
        @change="onStatusChange"
        v-decorator="[
          'status',
          { valuePropName: 'checked', initialValue: true },
        ]"
      />
    </a-form-item>
    <div class="a-center">
      <aButton type="primary" size="large" html-type="submit">
        Cadastrar
      </aButton>
    </div>
  </aForm>
</template>

<script>
import roles from "@/json/roles.json";

export default {
  name: "CreateUser",
  data() {
    return {
      form: this.$form.createForm(this),
      loading: false,
      roles,
      imageUrl: "",
    };
  },
  mounted() {
    this.form.setFieldsValue({
      email: "E-mail",
    });

    setTimeout(() => {
      this.form.resetFields();
    }, 900);
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();

      this.$store.state.createUser = false;
      this.form.validateFields((err, values) => {
        if (!err) {
          values.avatar ? values.avatar : "";
          this.createUser(values);
        }
      });
    },
    createUser(values) {
      this.$store.state.createUser = false;
      this.$http
        .post("/user/create", values)
        .then(({ data }) => {
          this.$message.success(data.message);
          this.loading = false;
          this.$emit("userSubmitted");
          this.form.resetFields();
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
          this.loading = false;
        });
    },
    onStatusChange(checked) {
      console.log(`a-switch to ${checked}`);
    },
    selectRole(value) {
      console.log(`selected ${value}`);
    },
    handleChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        info;
      }
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue("userPassword")) {
        callback("As senhas digitadas não são iguais.");
      } else {
        callback();
      }
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
  },
};
</script>
