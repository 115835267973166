<template>
  <aForm :form="form" @submit="handleSubmit">
    <aRow :gutter="[20, 0]" type="flex">
      <aCol :span="12">
        <a-form-item class="travel-input">
          <label for :class="'filled'">Nome</label>
          <a-input
            size="default"
            v-decorator="[
              'first_name',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            placeholder="Primeiro nome"
          >
          </a-input>
        </a-form-item>
      </aCol>

      <aCol :span="12">
        <a-form-item class="travel-input">
          <label for class="filled">Sobrenome</label>
          <a-input
            size="default"
            v-decorator="[
              'last_name',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            placeholder="Sobrenome"
          >
          </a-input>
        </a-form-item>
      </aCol>

      <aCol :span="12">
        <a-form-item class="travel-input">
          <label for class="filled">E-mail</label>
          <a-input
            autoComplete="off"
            size="default"
            v-decorator="[
              'email',
              {
                rules: [
                  {
                    type: 'email',
                    message: 'Escreva um e-mail válido',
                  },
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            placeholder="E-mail"
          >
          </a-input>
        </a-form-item>
      </aCol>

      <aCol v-if="$root.isAdmin()" :span="12">
        <a-form-item class="travel-input">
          <label for class="filled">Função do usuário</label>
          <a-select
            default-value=""
            show-search
            placeholder="Função do usuário"
            @change="selectRole"
            v-decorator="[
              'role',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-select-option
              v-for="(item, index) of roles"
              :key="index"
              :value="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </aCol>
      <aCol v-else :span="12"> </aCol>

      <aCol :span="12">
        <a-form-item class="travel-input">
          <label for class="filled">Celular</label>
          <a-input
            v-mask="'(##) # ####-####'"
            v-decorator="[
              'mobile_phone',
              {
                rules: [
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            placeholder="Celular"
          >
          </a-input>
        </a-form-item>
      </aCol>

      <aCol :span="12">
        <a-form-item class="travel-input">
          <label for class="filled">Telefone Fixo/Ramal</label>
          <a-input
            v-mask="'(##) ####-####'"
            v-decorator="[
              'phone',
              {
                rules: [
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            placeholder="Telefone Fixo/Ramal"
          >
          </a-input>
        </a-form-item>
      </aCol>

      <aCol :span="12">
        <a-form-item class="travel-input">
          <label for class="filled">Nascimento</label>
          <a-date-picker
            placeholder="Nascimento"
            format="DD/MM/YYYY"
            v-mask="'##/##/####'"
            :showToday="false"
            @openChange="defaultDate"
            v-decorator="[
              'birthday',
              {
                rules: [
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
          </a-date-picker>
        </a-form-item>
      </aCol>

      <div class="clearboth" style="color: transparent">.</div>

      <aCol :span="12">
        <a-form-item class="travel-input">
          <label for class="filled">Senha</label>
          <a-input-password
            class="travel-input"
            size="default"
            v-decorator="[
              'userPassword',
              {
                rules: [
                  {
                    required: false,
                    message: 'Escreva sua nova senha.',
                  },
                ],
              },
            ]"
            type="password"
            placeholder="Insira a senha"
          >
          </a-input-password></a-form-item
      ></aCol>

      <aCol :span="12">
        <a-form-item class="travel-input">
          <label for class="filled">Repita a senha</label>
          <a-input
            class="travel-input"
            size="default"
            v-decorator="[
              'password',
              {
                rules: [
                  {
                    required: false,
                    message: 'Repita a  senha.',
                  },
                  {
                    validator: compareToFirstPassword,
                  },
                ],
              },
            ]"
            type="password"
            placeholder="Repita a senha"
          >
          </a-input>
        </a-form-item>
      </aCol>
    </aRow>

    <a-form-item v-if="$root.isAdmin()">
      Status:
      <a-switch
        size="small"
        @change="onStatusChange"
        v-decorator="[
          'status',
          {
            valuePropName: 'checked',
            initialValue: userData.status == '1' ? true : false,
          },
        ]"
      />
    </a-form-item>

    <a-form-item class="travel-avatar-wrapper mb-30">
      <div class="image-uploader-container">
        <a-avatar
          v-if="theAvatar"
          :src="theAvatar"
          class="upper"
          style="color: #be4178; background-color: #ffe3ef"
          :size="100"
        >
        </a-avatar>
        <a-upload
          v-else
          name="file"
          :multiple="false"
          :action="`${baseUrl}?module=users/avatar&id=${userData.id}&sub-folder=`"
          :remove="removeUploadedFile"
          :headers="{
            authorization: `Bearer ${this.$store.state.token}`,
          }"
          @change="uploadedFile"
          v-model="theAvatar"
        >
          <a-button type="dashed" shape="circle" size="large">
            <a-icon type="user" />
          </a-button>
        </a-upload>
      </div>

      <a-button
        v-if="theAvatar"
        type="danger"
        ghost
        class="remove"
        shape="circle"
        @click="removeAvatar()"
      >
        <a-icon type="delete" />
      </a-button>
    </a-form-item>

    <a-form-item>
      <a-button type="primary" size="large" html-type="submit">
        Atualizar
      </a-button>
    </a-form-item>
  </aForm>
</template>

<script>
import roles from "@/json/roles.json";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export default {
  props: {
    userData: Object,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      baseUrl: "https://api.viajar.tur.br/v1/media/upload-to-s3",
      roles,
      loading: false,
      imageUrl: "",
      theAvatar: null,
      imageBase64: null,
    };
  },
  beforeMount() {
    let time = null;

    if (this.$route.meta.slug == "profile") {
      time = 1200;
    } else {
      time = 300;
    }
    setTimeout(() => {
      this.form.setFieldsValue({
        first_name: this.userData.first_name,
        last_name: this.userData.last_name,
        email: this.userData.email,
        role: this.userData.role,
        mobile_phone: this.userData.meta.mobile_phone,
        phone: this.userData.meta.phone,
        birthday: this.userData.meta.birthday,
        password: ".",
        userPassword: ".",
        avatar: this.userData.meta.avatar,
      });

      this.theAvatar = this.userData.meta.avatar;

      this.form.setFieldsValue({
        password: "",
        userPassword: "",
      });
    }, time);
  },
  methods: {
    uploadedFile(response) {
      console.log("uploadedFile", response);
      if (response.file.response.url != undefined) {
        this.theAvatar = response.file.response.url;
      }
    },
    removeUploadedFile() {
      this.theAvatar = "";
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        values.avatar = this.theAvatar;
        console.log(">>>", err, values, this.userData.status);
        if (!err) {
          if (values.userPassword && values.password) {
            values.password;
          } else {
            values.password = "";
          }

          values.id = this.userData.id;

          values.status === true ? (values.status = 1) : (values.status = 0);

          this.updateUser(values, this.userData.id);
        }
      });
    },
    getUser(id) {
      this.$http
        .post("/user/read_one", {
          id: id,
        })
        .then(({ data }) => {
          this.userData = data;
          this.$emit("updateUserData", data);
          // this.editUser = true;
        })
        .finally(() => {});
    },
    updateUser(values, id) {
      if (!this.$root.isAdmin()) {
        values.role = this.userData.role;
        values.status = this.userData.status;
      }

      if (this.userData.status != values.status) {
        values.action = "update-status";
        values.description = `${this.$store.state.userData.first_name} ${
          this.$store.state.userData.last_name
        } alterou o status do usuário ID ${values.id} para ${
          values.status == 0 ? "Desativado" : "Ativo"
        }.`;
      }

      values.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      this.$http
        .post("/user/update", values)
        .then(({ data }) => {
          this.$message.success(data.message);
          this.loading = false;
          this.getUser(id);
          this.$emit("userSubmitted");
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
          this.loading = false;
        });
    },
    onStatusChange(checked) {
      checked;
    },
    selectRole(value) {
      value;
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue("userPassword")) {
        callback("As senhas digitadas não são iguais.");
      } else {
        callback();
      }
    },
    removeAvatar() {
      this.userData.meta.avatar = "";
      this.form.setFieldsValue({
        avatar: "",
      });
      this.theAvatar = "";
    },
    handleChange(info) {
      if (this.isValidFile) {
        getBase64(info.file.originFileObj, (imageBase64) => {
          this.imageBase64 = imageBase64;
          this.theAvatar = imageBase64;

          setTimeout(() => {
            this.form.setFieldsValue({
              avatar: imageBase64,
            });
          }, 1);
        });
      }
    },
    beforeUpload(file) {
      const isValidImage =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml";
      if (!isValidImage) {
        this.$message.error("Este arquivo não é válido!");
        return;
      }
      const validSize = file.size / 1024 / 1024 < 2;
      if (!validSize) {
        this.$message.error("A imagem deve ter menos que 2MB!");
        return;
      }
      this.isValidFile = true;
      return isValidImage && validSize;
    },
  },
};
</script>
