var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aForm',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('aRow',{attrs:{"gutter":[20, 0],"type":"flex"}},[_c('aCol',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v("Nome")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'first_name',
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            'first_name',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],attrs:{"size":"default","placeholder":"Primeiro nome"}})],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input"},[_c('label',{staticClass:"filled",attrs:{"for":""}},[_vm._v("Sobrenome")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'last_name',
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            'last_name',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],attrs:{"size":"default","placeholder":"Sobrenome"}})],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input"},[_c('label',{staticClass:"filled",attrs:{"for":""}},[_vm._v("E-mail")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email',
            {
              rules: [
                {
                  type: 'email',
                  message: 'Escreva um e-mail válido',
                },
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            'email',\n            {\n              rules: [\n                {\n                  type: 'email',\n                  message: 'Escreva um e-mail válido',\n                },\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],attrs:{"autoComplete":"off","size":"default","placeholder":"E-mail"}})],1)],1),_vm._v(" "),(_vm.$root.isAdmin())?_c('aCol',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input"},[_c('label',{staticClass:"filled",attrs:{"for":""}},[_vm._v("Função do usuário")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'role',
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            'role',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],attrs:{"default-value":"","show-search":"","placeholder":"Função do usuário"},on:{"change":_vm.selectRole}},_vm._l((_vm.roles),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v("\n            "+_vm._s(item.name)+"\n          ")])}),1)],1)],1):_c('aCol',{attrs:{"span":12}}),_vm._v(" "),_c('aCol',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input"},[_c('label',{staticClass:"filled",attrs:{"for":""}},[_vm._v("Celular")]),_vm._v(" "),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:('(##) # ####-####'),expression:"'(##) # ####-####'"},{name:"decorator",rawName:"v-decorator",value:([
            'mobile_phone',
            {
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            'mobile_phone',\n            {\n              rules: [\n                {\n                  required: false,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"Celular"}})],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input"},[_c('label',{staticClass:"filled",attrs:{"for":""}},[_vm._v("Telefone Fixo/Ramal")]),_vm._v(" "),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:('(##) ####-####'),expression:"'(##) ####-####'"},{name:"decorator",rawName:"v-decorator",value:([
            'phone',
            {
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            'phone',\n            {\n              rules: [\n                {\n                  required: false,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"Telefone Fixo/Ramal"}})],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input"},[_c('label',{staticClass:"filled",attrs:{"for":""}},[_vm._v("Nascimento")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
            'birthday',
            {
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            'birthday',\n            {\n              rules: [\n                {\n                  required: false,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"Nascimento","format":"DD/MM/YYYY","showToday":false},on:{"openChange":_vm.defaultDate}})],1)],1),_vm._v(" "),_c('div',{staticClass:"clearboth",staticStyle:{"color":"transparent"}},[_vm._v(".")]),_vm._v(" "),_c('aCol',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input"},[_c('label',{staticClass:"filled",attrs:{"for":""}},[_vm._v("Senha")]),_vm._v(" "),_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'userPassword',
            {
              rules: [
                {
                  required: false,
                  message: 'Escreva sua nova senha.',
                },
              ],
            },
          ]),expression:"[\n            'userPassword',\n            {\n              rules: [\n                {\n                  required: false,\n                  message: 'Escreva sua nova senha.',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"size":"default","type":"password","placeholder":"Insira a senha"}})],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input"},[_c('label',{staticClass:"filled",attrs:{"for":""}},[_vm._v("Repita a senha")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                {
                  required: false,
                  message: 'Repita a  senha.',
                },
                {
                  validator: _vm.compareToFirstPassword,
                },
              ],
            },
          ]),expression:"[\n            'password',\n            {\n              rules: [\n                {\n                  required: false,\n                  message: 'Repita a  senha.',\n                },\n                {\n                  validator: compareToFirstPassword,\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"size":"default","type":"password","placeholder":"Repita a senha"}})],1)],1)],1),_vm._v(" "),(_vm.$root.isAdmin())?_c('a-form-item',[_vm._v("\n    Status:\n    "),_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'status',
        {
          valuePropName: 'checked',
          initialValue: _vm.userData.status == '1' ? true : false,
        },
      ]),expression:"[\n        'status',\n        {\n          valuePropName: 'checked',\n          initialValue: userData.status == '1' ? true : false,\n        },\n      ]"}],attrs:{"size":"small"},on:{"change":_vm.onStatusChange}})],1):_vm._e(),_vm._v(" "),_c('a-form-item',{staticClass:"travel-avatar-wrapper mb-30"},[_c('div',{staticClass:"image-uploader-container"},[(_vm.theAvatar)?_c('a-avatar',{staticClass:"upper",staticStyle:{"color":"#be4178","background-color":"#ffe3ef"},attrs:{"src":_vm.theAvatar,"size":100}}):_c('a-upload',{attrs:{"name":"file","multiple":false,"action":`${_vm.baseUrl}?module=users/avatar&id=${_vm.userData.id}&sub-folder=`,"remove":_vm.removeUploadedFile,"headers":{
          authorization: `Bearer ${this.$store.state.token}`,
        }},on:{"change":_vm.uploadedFile},model:{value:(_vm.theAvatar),callback:function ($$v) {_vm.theAvatar=$$v},expression:"theAvatar"}},[_c('a-button',{attrs:{"type":"dashed","shape":"circle","size":"large"}},[_c('a-icon',{attrs:{"type":"user"}})],1)],1)],1),_vm._v(" "),(_vm.theAvatar)?_c('a-button',{staticClass:"remove",attrs:{"type":"danger","ghost":"","shape":"circle"},on:{"click":function($event){return _vm.removeAvatar()}}},[_c('a-icon',{attrs:{"type":"delete"}})],1):_vm._e()],1),_vm._v(" "),_c('a-form-item',[_c('a-button',{attrs:{"type":"primary","size":"large","html-type":"submit"}},[_vm._v("\n      Atualizar\n    ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }